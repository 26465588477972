.cont {
    display: flex;
    flex-direction: column;
margin-left: auto;
margin-right: auto;

}
.cont h1{
font-weight: 700;
text-transform: uppercase;
margin-left: auto;
margin-right: auto;
}
.cont a{
    width: 100px;
    margin-top: 30px;
    margin-left: auto;
margin-right: auto;
}
